import React, { FunctionComponent } from "react"
import camelCase from "lodash/camelCase"
import styles from "./Pill.module.css"

type PillVariant = "top-right" | "bottom-right" | "bottom-left" | "top-left" | "center-right" | "top-left-with-top-space" | "top-left-with-top-space-rotated" | "rotated" | "rotated-high"

interface Props {
  bgClass: string
  variant: PillVariant
}

function getVariantStyle(variant: PillVariant) {
  const className = camelCase(variant)
  return styles[className]
}



const Pill: FunctionComponent<Props> = ({ bgClass, children, variant }) => {
  const variantStyle = getVariantStyle(variant)

  return (
    <div className={`${styles.pill} ${variant === 'rotated' && styles.pillRotated} ${variant === 'rotated-high' && styles.pillRotatedHigh}`}>
      <div className={`${bgClass} ${styles.background} ${variantStyle}`} />
      <div className={`${styles.content}`}>{children}</div>
    </div>
  )
}

export default Pill
